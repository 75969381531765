.crm-mertel *{
  font-family: Gotham Book;
}

.crm-mertel .comments{
  background-color: #F6F6F6;
  margin-bottom: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.crm-mertel label{
  color: #B0B0B0;
  font-size: 15px;
  line-height: 24px;
}

.crm-mertel input{
  background: #FFFFFF;
  border: 0.953537px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.13px;
  font-size: 11px;
  font-weight: bold;
  align-items: center;
  color: #636363;
}

.crm-mertel .active-tag{
  border: 2px solid #578FFB;
  box-sizing: border-box;
  border-radius: 4.13px;
  margin-right: 6px;
}

.crm-mertel .active-tag button{
  width: 95px;
  height: 33px;
  font-size: 14.29px;
  line-height: 17px;
  text-align: center;
  color: #578FFB;
}

.crm-mertel .tag{
  background-color: #578FFB;
  border: 2px solid #578FFB;
  box-sizing: border-box;
  border-radius: 4.13px;
  margin-right: 6px;
}

.crm-mertel .tag button{
  width: 95px;
  height: 33px;
  color: white;
  font-size: 14.29px;
  line-height: 17px;
  text-align: center;
}

.crm-mertel .status{
  color: white;
  margin-right: 80px;
  margin-top: -24px;
  padding-top: 24px;
  height: 100px;
  width: 75px;
  text-align: center;
  font-size: 14.29px;
}

.crm-mertel .flag{
  position: absolute;
  background-color: white;
  right: 87px;
  top: 40px;
  transform: rotate(45deg);
  height: 60px;
  width: 60px;
}

.crm-mertel .title{
  font-size: 23px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;

  color: #000000;

  border-radius: 1.72185px;
  border: none;
}

.crm-mertel .title:focus{
  border: 0.953537px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.13px;
}

.crm-mertel .textarea {
  border: 0.953537px solid #CCCCCC;
  font-size: 15px;
  border-radius: 4.13px;
  color: #3A3A3A;
}

.crm-mertel .textarea:focus {
  border: 0.953537px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.13px;
}

//--------SUBTASKS
.crm-mertel th{
  color: #B0B0B0;
  font-size: 15px;
  line-height: 24px;
  border: none !important;
}

.crm-mertel tbody tr{
  border-top: 1px solid #E0E0E0 !important;
}

.crm-mertel tbody tr .input{
  border: none;
  width: 754px;
  background-color: inherit;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.crm-mertel tbody tr .input:focus{
  background-color: white;
  border: 0.953537px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.13px;
}

.crm-mertel tbody tr .input-visible{
  border: 0.953537px solid #CCCCCC;
  width: 754px;
  background-color: white;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.crm-mertel tbody tr .input-visible:focus{
  background-color: white;
  border: 0.953537px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4.13px;
}

.crm-mertel .btn-link{
  background-color: inherit;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.crm-mertel tbody .btn-link:hover{
  background-color: #578FFB;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.crm-mertel .btn{
  background-color: #578FFB;
  color: white;
  height: 30px;
  min-width: 30px;
  border-radius: 3px;
}

.crm-mertel .btn:disabled{
  opacity: 1;
  background-color: #578FFB;
  color: white;
  height: 30px;
  min-width: 30px;
  border-radius: 3px;
}

.crm-mertel thead tr{
  border-top: none !important;
}

.crm-mertel td{
  border: none !important;
}

.crm-mertel tbody{
  border: none !important;
}

.crm-mertel thead{
  border: none !important;
}

.crm-mertel .table-checkbox{
  width: 20px;
}

//--------CUSTOM-CHECKBOX
/* Customize the label (the container) */
.crm-mertel .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.crm-mertel .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.crm-mertel .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 0.95px solid #636363;
  border-radius: 1.91px;
  background-color: white;
}

/* On mouse-over, add a grey background color */
.crm-mertel .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.crm-mertel .container input:checked ~ .checkmark {
  background-color: #578FFB;
  border-color: #578FFB;
}

/* Create the checkmark/indicator (hidden when not checked) */
.crm-mertel .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.crm-mertel .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.crm-mertel .container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//--------COMMENTS
.crm-mertel .time{
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #A6A6A6;
}

.crm-mertel .file{
  display: inline-block;
  padding: 0px;
  padding-top: 2px;
  padding-left: 5px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12.5px;
  align-items: center;
}

.crm-mertel .file-name{
  margin-bottom: 0px;
  margin-top: -2px;
  color: #ccc;
}

.crm-mertel .file button{
  width: 15px;
  background-color: white;
  color: #ccc;
}

.crm-mertel .file button:hover{
  background-color: white;
}
